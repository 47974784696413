import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concat,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DropdownItem } from '../../../../../shared/models/common.model';
import { User } from '../../../../../shared/models/user.models';
import { DropdownService } from '../../../../../shared/service/dropdown.service';
import { CertificateStatus } from '../../../../certificate/shared/certificate.model';
import { Member } from '../../../../loa/shared/loa.model';
import { ApproverDropdown } from '../../../model/memo.model';
import { DdocLawSectionObj } from '../../upload-memos/loa-customizer/signing-law-section-radio/signing-law-section-radio.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-approval-dropdown',
  templateUrl: './approval-dropdown.component.html',
  styleUrls: ['./approval-dropdown.component.scss'],
})
export class ApprovalDropdownComponent
  implements OnChanges, OnInit, OnDestroy
{
  certificateStatus: CertificateStatus = null;
  people$: Observable<ApproverDropdown[]>;
  peopleInJobPosition$ = new BehaviorSubject<ApproverDropdown[]>([]);
  peopleInput$ = new BehaviorSubject<string>(null);
  peopleLoading = false;

  jobPositionItems$: Observable<any>;
  jobPositionInput$ = new Subject<string>();
  jobPositionLoading = false;

  subscription = new Subscription();
  curLang = 'en';

  @Input() clearable = true;
  @Input() selectedApprovals: number[] = [];
  @Input() approval: Partial<Member>;

  private _jobPosition: string = null;
  jobPosition$ = new BehaviorSubject(null);
  @Input() jobPosition: JobPositionDropdownItem;
  @Output() jobPositionChange =
    new EventEmitter<JobPositionDropdownItem>();
  // @Input() set jobPosition(v: string) {
  //   this._jobPosition = v;
  //   this.jobPosition$.next(v);
  // }
  // get jobPosition(): string {
  //   return this._jobPosition;
  // }

  @Input() disabled = false;
  @Input() disabledApprover = false;
  @Input() freeLawSelection = false;
  @Input() enableAttachSignature = false;
  @Input() ddocEnable = false;
  @Input() ddocLawSection: DdocLawSectionObj;
  @Input() allowLOAChange = true;
  @Output() approvalChange = new EventEmitter();
  @Output() ddocLawSectionChange =
    new EventEmitter<DdocLawSectionObj>();
  @Input() isContract = false;

  constructor(
    private dropdownService: DropdownService,
    public translate: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ddocEnable) {
      if (!changes.ddocEnable.currentValue) {
        this.ddocLawSection = { lawSection: null };
      }
    }
  }

  ngOnInit(): void {
    this.setJobPosition();
    this.setPeople();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSelectJobPosition(v) {
    this.jobPosition = v;
    this.jobPositionChange.emit(v);
    this.jobPosition$.next(v);
  }

  setPeople(): void {
    // when selected job position, then clear selected person and load all people in job position
    const sub = concat(
      of([] as ApproverDropdown[]),
      this.jobPosition$.pipe(
        distinctUntilChanged(),
        skip(1), // skip initial value
        tap(() => (this.peopleLoading = true)),
        tap((job) => {
          this.approval = null;
          this.approvalChange.emit(null);
        }),
        switchMap((job) => {
          if (!job?.value?.job_position) {
            return of([]);
          }
          return this.getPeopleList(null, job.value.job_position);
        }),
        catchError(() => of([] as ApproverDropdown[])),
        tap((people) => {
          // auto select if only 1 item
          if (people?.length === 1) {
            this.approval = people[0];
            this.approvalChange.emit(this.approval);
          }
        }),
        tap(() => (this.peopleLoading = false)),
      ),
    ).subscribe((people) => {
      this.peopleInJobPosition$.next(people);
    });
    this.subscription.add(sub);

    const cleanPeopleInput$ = this.peopleInput$.pipe(
      distinctUntilChanged(),
      // filter((term) => term != null && term !== ''),
      debounceTime(300),
    );

    // trigger when load people in job position finished and search people
    this.people$ = concat(
      of([] as ApproverDropdown[]),
      combineLatest([
        this.peopleInJobPosition$,
        cleanPeopleInput$,
      ]).pipe(
        tap(() => (this.peopleLoading = true)),
        switchMap(([peopleInJobPosition, query]) => {
          if (query) {
            return this.getPeopleList(
              query,
              this.jobPosition?.value?.job_position,
            );
          } else {
            return of(peopleInJobPosition);
          }
        }),
        catchError(() => of([] as ApproverDropdown[])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }

  getPeopleList(query = '', job_position?: string) {
    let params: { [k: string]: any } = {
      type: 'people',
      all: 'true',
      group: 'General,Contract Management',
      with_signature: true,
    };
    if (query) {
      params.query = query;
    }

    if (job_position) {
      params = {
        ...params,
        job_position,
      };
    }

    if (this.enableAttachSignature) {
      params.with_signature = true;
    }
    return this.dropdownService.getDropdown(params).pipe(
      map((obj) =>
        obj.people.map(
          (people: DropdownItem<number, PeopleDropdownContext>) => {
            const approver: ApproverDropdown = {
              person: people.value,
              full_name: people.label,
              context: people.context,
            };
            if (this.enableAttachSignature) {
              approver.signature = people.context.signature;
            }
            return approver;
          },
        ),
      ),
      map((people) => {
        return people.filter(
          (person) => !this.selectedApprovals.includes(person.person),
        );
      }),
    );
  }

  setJobPosition(): void {
    this.jobPositionItems$ = concat(
      of([] as string[]),
      this.jobPositionInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.jobPositionLoading = true)),
        switchMap((query) => {
          const params: { [k: string]: string } = {
            type: 'job_position',
            query,
          };

          return this.dropdownService.getDropdown(params).pipe(
            map((obj: any) => {
              return obj.job_position as JobPositionDropdownItem[];
            }),
            // map((obj: any) =>
            //   obj.job_position.map((job) => {
            //     return job?.label;
            //   }),
            // ),
            // map((jobs) => {
            //   return jobs;
            // }),
          );
        }),
        catchError(() => of([] as string[])),
        tap(() => (this.jobPositionLoading = false)),
      ),
    );
  }

  onSelectApproval(dropdown: ApproverDropdown): void {
    this.approvalChange.emit(dropdown);
    if (
      dropdown?.context?.job_position ||
      dropdown?.context?.job_position_en
    ) {
      // if (this.translate.currentLang === 'th') {
      //   this.jobPosition = dropdown?.context?.job_position;
      // } else {
      //   this.jobPosition = dropdown?.context?.job_position_en;
      // }
      this.jobPosition = {
        label: dropdown?.context?.job_position,
        value: {
          job_position: dropdown?.context?.job_position,
          job_position_en: dropdown?.context?.job_position_en,
        },
      };
    }
    this.jobPositionChange.emit(this.jobPosition);
  }

  getPlacement(): string {
    return window.innerWidth > 1400 ? 'left' : 'auto';
  }
}

interface PeopleDropdownContext extends Partial<User> {
  signature: string;
}

export interface JobPositionDropdownItem {
  label: string;
  value: {
    job_position: string;
    job_position_en: string;
  };
}
