import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'translateField',
})
export class TranslateFieldPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    obj: any,
    thField = 'first_name',
    enField?: string,
  ): Observable<any> {
    if (!enField) {
      enField = thField + '_en';
    }

    const thVal = obj?.[thField] ?? obj?.[enField];
    const enVal = obj?.[enField] ?? obj?.[thField];

    const initialVal =
      this.translate.currentLang === 'th' ? thVal : enVal;

    return this.translate.onLangChange.pipe(
      map((event) => {
        return event.lang === 'th' ? thVal : enVal;
      }),
      startWith(initialVal),
    );
  }
}
