<div class="row w-100">
  <div class="col-12 col-md-6 my-2">
    <ng-select [items]="jobPositionItems$ | async"
        class="select-jobposition"
        [loading]="jobPositionLoading"
        placeholder="{{'MEMOS.SELECT-JOB-POSITION'|translate}}"
        [clearable]="clearable"
        [disabled]="disabled || disabledApprover"
        [ngModel]="jobPosition"
        (ngModelChange)="onSelectJobPosition($event)"
        [typeahead]="jobPositionInput$"
        [minTermLength]="2"
        typeToSearchText="{{ 'user.type-search' | translate }}"
        [notFoundText]="'SELECT.No items found'|translate">
      <ng-template ng-label-tmp
        let-item="item">
        <span>{{ translate.currentLang === 'th'
          ? item?.value?.job_position
          : (item?.value?.job_position_en ?? item?.value?.job_position)}}</span>
      </ng-template>
      <ng-template ng-option-tmp
        let-item="item">
        <span>{{ translate.currentLang === 'th'
          ? item?.value?.job_position
          : (item?.value?.job_position_en ?? item?.value?.job_position)}}</span>
      </ng-template>
    </ng-select>
  </div>
  <div class="col-12 col-md-6 my-2">
    <ng-select [items]="people$ | async"
      [loading]="peopleLoading"
      placeholder="{{'MEMOS.SELECT-APPROVER'|translate}}"
      [clearable]="clearable"
      [disabled]="disabled || disabledApprover"
      [(ngModel)]="approval"
      (change)="onSelectApproval($event);"
      [typeahead]="peopleInput$"
      [minTermLength]="2"
      typeToSearchText="{{ 'user.type-search' | translate }}"
      [notFoundText]="'SELECT.No items found'|translate">
      <ng-template ng-label-tmp
        let-item="item">
        <span>{{ translate.currentLang === 'th'
          ? (item?.context?.name ?? item?.full_name)
          : (item?.context?.name_en ?? item?.full_name_en ?? item?.context?.name ?? item?.full_name)}}
          ({{item.context.bu_1_department_codes}})</span>
      </ng-template>
      <ng-template ng-option-tmp
        let-item="item">
        <span>{{ translate.currentLang === 'th'
          ? (item?.context?.name ?? item?.full_name)
          : (item?.context?.name_en ?? item?.full_name_en ?? item?.context?.name ?? item?.full_name)}}
          ({{item.context.bu_1_department_codes}})</span>
      </ng-template>
  </ng-select>
  </div>
</div>
<ng-template #certificateStatusTemplate>
  <div class="validity-status"
      [ngSwitch]="certificateStatus">
    <ng-container *ngSwitchCase="'valid'">
      <span class="fas fa-check-circle text-success"></span>
      <label class="text-success">{{ 'CERTIFICATE.VALID' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.EXPIRED' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'not_created'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.NOT-CREATED' | translate }}</label>
    </ng-container>
  </div>
</ng-template>
<ng-container *ngIf="ddocEnable">
  <app-signing-law-section-radio [signerId]="approval?.person"
      [isContract]="isContract"
      [certStatusSec26]="approval?.m26_certificate_status"
      [certStatusSec28]="approval?.m28_certificate_status"
      [(ddocLawSection)]="ddocLawSection"
      (ddocLawSectionChange)="ddocLawSectionChange.emit($event)"
      [freeLawSelection]="freeLawSelection"
      [allowLOAChange]="allowLOAChange"
      (signerRejected)="approvalChange.emit({ person: null })">
  </app-signing-law-section-radio>
</ng-container>
