<ng-template #downloadModal>
  <div class="modal-header">
    <h5>{{ 'CONTRACT.DOWNLOAD-CONTRACT-FILES' | translate }}</h5>
    <button type="button"
      class="close"
      aria-label="Close"
      (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body px-1 px-md-3 px-lg-5">
    <ul class="list-group">
      <!-- Level 1 -->
      <li class="list-group-item"
        [style.--c]="hoverBackgroundColor"
        [class.selected]="selectedAll">
        <input type="checkbox"
          class="cross-compatible"
          id="selectAll"
          [ngModel]="selectedAll"
          (change)="selectAll()">
        <label class="form-check-label pl-2"
          for="selectAll">
          {{ 'DASHBOARD.SELECT-ALL' | translate }}
        </label>
      </li>

      <!-- Level 2 -->
      <li class="list-group-item indent-1"
        [style.--c]="hoverBackgroundColor"
        [class.selected]="payload.include_documents">
        <input type="checkbox"
          class="cross-compatible"
          id="contract-file"
          (change)="deselectAll()"
          [(ngModel)]="payload.include_documents">
        <label class="form-check-label pl-2"
          for="contract-file">
          {{ 'CONTRACT.CONTRACT-FILE' | translate }}
        </label>
      </li>
      <li class="list-group-item indent-1"
        [style.--c]="hoverBackgroundColor"
        [class.selected]="payload.include_completion_report">
        <input type="checkbox"
          class="cross-compatible"
          id="certificate-file"
          (change)="deselectAll()"
          [(ngModel)]="payload.include_completion_report">
        <label class="form-check-label pl-2"
          for="certificate-file">
          {{ 'CONTRACT.CERTIFICATE-FILE' | translate }}
        </label>
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <div class="row justify-content-center px-2">
      <button class="btn p-btn btn-outline mx-2"
        (click)="close()">
        {{ 'MEMOS.CANCEL' | translate }}
      </button>

      <button class="btn p-btn mx-2"
        type="button"
        appSetTheme
        (click)="onConfirmClick()"
        [disabled]="!canSubmit">
        {{ 'MEMOS.DOWNLOAD' | translate }}
      </button>
    </div>
  </div>
</ng-template>