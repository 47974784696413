import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from '../../../modules/memos/service/memo.service';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { cloneDeep } from 'lodash';
import { DownloadWithAttachmentsPayload } from 'src/app/modules/memos/model/memo.model';

@Component({
  selector: 'app-download-with-attachments-modal',
  templateUrl: './download-with-attachments-modal.component.html',
  styleUrls: ['./download-with-attachments-modal.component.scss'],
})
export class DownloadWithAttachmentsModalComponent {
  @ViewChild('downloadModal', { static: true })
  downloadModal: ElementRef;

  @Output() download =
    new EventEmitter<DownloadWithAttachmentsPayload>();

  themeList: ThemeList;

  memoIds: number[] = [];
  // downloadInfos: DownloadInfoWithState[] = [];
  payload: DownloadWithAttachmentsPayload;

  selectedAll: boolean;

  hoverBackgroundColor = '#EEEEEE';

  constructor(
    public modalService: NgbModal,
    private themeService: ThemeService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  open(memoIds: number[]): void {
    this.memoIds = memoIds;
    this.selectedAll = false;
    this.initPayload();
    this.modalService.open(this.downloadModal, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
      keyboard: false,
      windowClass: 'custom-modal-dialog',
    });
  }

  initPayload(): void {
    this.payload = {
      memo_list: this.memoIds,
      include_documents: false,
      include_completion_report: false,
      include_attachment: false,
      include_reference: false,
    };
  }

  selectAll(): void {
    this.selectedAll = !this.selectedAll;
    this.payload.include_documents =
      this.payload.include_completion_report = this.selectedAll;
  }

  deselectAll(): void {
    this.selectedAll =
      this.payload.include_documents &&
      this.payload.include_completion_report;
  }

  close(): void {
    this.modalService.dismissAll();
  }

  onConfirmClick(): void {
    const payload: DownloadWithAttachmentsPayload = cloneDeep(
      this.payload,
    );
    this.download.emit(payload);
    this.close();
  }

  get canSubmit(): boolean {
    return (
      this.payload?.include_documents ||
      this.payload?.include_completion_report
    );
  }
}
